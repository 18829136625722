.inner{
    overflow: hidden
  }

  .inner img {
    transition: all 1.5s ease
  }

  .inner:hover img{
    transform: scale(1.5)
  }